import React from 'react';
import { connect } from 'react-redux';
import { update_order_with_payment } from '../../actions/order/orderActionDispatch';
import { FAILED, SUCCESS, WALLET } from '../../constants';
import { messageNotificationSuccess } from '../../utils';

class Index extends React.Component {
componentDidUpdate(prevProps){
    if(prevProps && prevProps.walletOrderPayment !== this.props.walletOrderPayment){
        this.updatePayment()
    }
}
updatePayment = () =>{
    const {walletOrderPayment, update_order_with_payment, loader} = this.props
        if(walletOrderPayment.status === SUCCESS){
            let paymentInfo = {
                payment_method: WALLET,
                reference_number: walletOrderPayment.transaction_id,
                price: `${walletOrderPayment.amount}`,
                order_id: `${walletOrderPayment.order_id }`
            }
            update_order_with_payment(paymentInfo, ()=>{
            messageNotificationSuccess(walletOrderPayment.message,  "Order payment" )
            })
        }
        else if(walletOrderPayment.status === FAILED){
            messageNotificationSuccess(walletOrderPayment.message, "Order payment")

        }
        loader(false)
}
    render() {
        return false
    }
}
const mapStateToProps = ({wallet}) =>{
return{
    walletOrderPayment: wallet.walletOrderPayment
}
}
export default connect(mapStateToProps, {update_order_with_payment})(Index);
