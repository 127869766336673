import React from "react";
import "./App.less";
import { BrowserRouter as Router } from "react-router-dom";
import UserApp from "./module/Dashboardlayout";
import { Provider } from "react-redux";
import configureStore from "./common/store/store";
import PrivateRoute from "./common/privateRoute";
import { Route } from "react-router-dom";
import Login from "./module/authentication/login"
import Register from "./module/authentication/register"
import { Helmet } from 'react-helmet'
import VerifyAccount from "./module/authentication/register/VerifyAccount";
import RequestPasswordChange from "./module/authentication/recoverPassword/requestPasswordChange";
import Instruction from "./module/authentication/recoverPassword/Instruction";
import Otp from "./module/authentication/recoverPassword/otpWrapper";
import UpdatePassword from "./module/authentication/recoverPassword/UpdatePassword";
import RegisterSuccess from "./module/authentication/register/Success"

const store = configureStore();
function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Helmet>
                <link rel="icon" href={`${process.env.REACT_APP_MERCHANT_IMAGE}/logo192.png`} />
            </Helmet>
          <Route exact path="/login" component={Login} />
          <div className="user-app">
          <PrivateRoute path = "/user" component={UserApp} />
          </div>
          <Route exact path="/register" component={Register} />
          <Route exact path="/verify-account" component={VerifyAccount} />
          <Route exact path="/forgot-password" component={RequestPasswordChange} />
          <Route exact path="/instruction" component={Instruction} />
          <Route exact path="/otp" component={Otp} />
          <Route exact path="/update-password" component={UpdatePassword} />
          <Route exact path="/success" component={RegisterSuccess} />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
