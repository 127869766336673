import * as actiontypes from "../../actionTypes";

export const senderInfo = (info) => {
  return {
    type: actiontypes.GET_SENDER_DETAILS,
    payload: info
  };
};
export const receiverInfo = (info) => {
  return {
    type: actiontypes.GET_RECEIVER_DETAILS,
    payload: info
  };
};
export const orderFormStep = (formStep) => {
  return {
    type: actiontypes.ORDER_FORM_STEP,
    payload: formStep
  };
};
export const handleEditOrder = (status) => {
  return {
    type: actiontypes.HANDLE_EDIT,
    payload: status
  };
};
export const handleCheckPrice = (info) => {
  return {
    type: actiontypes.CHECK_PRICE,
    payload: info
  };
};
export const handleCheckPriceStatus = (status) => {
  return {
    type: actiontypes.CHECK_PRICE_STATUS,
    payload: status
  };
};
export const getDeliveryType = (delivery_type) => {
  return {
    type: actiontypes.DELIVERY_TYPE,
    payload: delivery_type
  };
};

export const getDeliveryTime = (delivery_time) => {
  return {
    type: actiontypes.DELIVERY_TIME,
    payload: delivery_time
  };
};
export const getPackageSize = (package_size) => {
  return {
    type: actiontypes.PACKAGE_SIZE,
    payload: package_size
  };
};
export const createClientOrder = (client_order) => {
  return {
    type: actiontypes.CREATE_CLIENT_ORDER,
    payload: client_order
  };
};
export const OrderStatus = (order_status) => {
  return {
    type: actiontypes.CLIENT_ORDER_STATUS,
    payload: order_status
  };
};
export const orderBills = (order_bills) => {
  return {
    type: actiontypes.CLIENT_ORDER_BILLS,
    payload: order_bills
  };
};
export const redeemCoupon = (coupon) => {
  return {
    type: actiontypes.REDEEM_COUPON,
    payload: coupon
  };
};
export const getCouponStatus = (info) => {
  return {
    type: actiontypes.COUPON_STATUS,
    payload: info
  };
};
export const getMyAnalytics = (info) => {
  return {
    type: actiontypes.MY_ANALYTICS,
    payload: info
  };
};
export const getOrderList = (order) => {
  return {
    type: actiontypes.GET_ORDER_LIST,
    payload: order
  };
};
export const getTrackingId = (tracking_id) => {
  return {
    type: actiontypes.GET_TRACKING_ID,
    payload: tracking_id
  };
};
export const trackOrders = (info) => {
  return {
    type: actiontypes.TRACK_ORDER,
    payload: info
  };
};
export const updateOrderWithPayment = (info) => {
  return {
    type: actiontypes.UPDATE_ORDER_WITH_PAYMENT,
    payload: info
  };
};