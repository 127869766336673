import { useEffect } from "react";
import { usePaystackPayment } from "react-paystack";
import { userInfo, PAYSTACK, WALLET_TRANSACTION, ORDER_PAYMENT, SUCCESS } from "../../constants";
import { confirm_payment } from "../../actions/wallet/walletDispatchers";
import { connect } from "react-redux";
import { update_order_with_payment } from "../../actions/order/orderActionDispatch"


const Paystack =(props)=>{

    const onSuccess = (reference) => {
   if(props.payWithPaystack.paymentType === WALLET_TRANSACTION){
    let paymentInfo = {
        transaction_id: reference.reference,
        gateway: PAYSTACK
    }
    props.confirm_payment(paymentInfo)
   }
   if(props.payWithPaystack.paymentType === ORDER_PAYMENT){
       const {initiatedPaymentInfo, loader, update_order_with_payment} = props
       if(reference.status === SUCCESS){
           let paymentInfo = {
            payment_method: PAYSTACK,
            reference_number: reference.reference,
            price: `${initiatedPaymentInfo.amount}`,
            order_id: initiatedPaymentInfo.order_id 
           }
          update_order_with_payment(paymentInfo, ()=>{
              loader(false)
           })
       }
   }
  };
  
  const onClose = () => {
    console.log("closed");
  };
    const { initiatedPaymentInfo } = props;
    const config = {
      reference: initiatedPaymentInfo
        ? initiatedPaymentInfo.transaction_id
        : null,
      email: userInfo.email ? userInfo.email : null,
      amount: initiatedPaymentInfo ? initiatedPaymentInfo.amount : null,
      publicKey: process.env.REACT_APP_PAYSTACK_API_KEY
    };

    const initializePayment = usePaystackPayment(config);
    useEffect(() => {
        if (props.payWithPaystack.enabled && initiatedPaymentInfo.amount) {
            initializePayment(onSuccess, onClose);
          }
      });

    return false;
  }


const mapStateToProps = ({ wallet }) => {
  return {
    initiatedPaymentInfo: wallet.initiatedPaymentInfo || {}
  };
};
export default connect(mapStateToProps, {confirm_payment, update_order_with_payment})(Paystack);
