import {
  senderInfo,
  receiverInfo,
  orderFormStep,
  handleEditOrder,
  handleCheckPrice,
  handleCheckPriceStatus,
  getDeliveryType,
  getDeliveryTime,
  getPackageSize,
  createClientOrder,
  OrderStatus,
  orderBills,
  redeemCoupon,
  getCouponStatus,
  getMyAnalytics,
  getOrderList, getTrackingId, trackOrders, updateOrderWithPayment
} from "./orderAction";
import {
  BASE_URL,
  MAPS_URL,
  axiosConfig,
  SUCCESS,
  FAILED, ORDER_BASE
} from "../../constants";
import axios from "axios";
import { constructCheckPriceData, currencyFormatter, messageNotificationSuccess, messageNotificationV2 } from "../../utils";

// dipatchers
export const get_sender_info = (pageRequest) => (dispatch) => {
  dispatch(senderInfo(pageRequest));
};
export const get_receiver_info = (pageRequest) => (dispatch) => {
  dispatch(receiverInfo(pageRequest));
};

export const getOrderFormStep = (pageRequest) => (dispatch) => {
  const page = pageRequest ? pageRequest : 0;
  dispatch(orderFormStep(page));
};
export const handle_edit = (pageRequest) => (dispatch) => {
  dispatch(handleEditOrder(pageRequest));
};
export const handle_check_price_status = (pageRequest) => (dispatch) => {
  dispatch(handleCheckPriceStatus(pageRequest));
};
export const client_order_bills = (pageRequest) => (dispatch) => {
  dispatch(orderBills(pageRequest));
};
export const get_tracking_id = (pageRequest) => (dispatch) => {
  dispatch(getTrackingId(pageRequest));
};
export const get_location_geocode = (pageRequest) => async (dispatch) => {
  let url = `${MAPS_URL}/geocode/json?address=${pageRequest}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  try {
    let response = await axios.get(url);
    let data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const handle_delivery_type = () => async (dispatch) => {
  let url = `${ORDER_BASE}/delivery-types`;
  try {
    const response = await axios.get(url);
    const data = await response.data;
    let details = [];
    for (let i = 0; i < data.length; i++) {
      let new_details = {
        id: data[i].id,
        name: data[i].name
      };
      details.push(new_details);
    }
    dispatch(getDeliveryType(details));
  } catch (error) {
    console.log(error.response);
  }
};

export const handle_delivery_time = () => async (dispatch) => {
  let url = `${ORDER_BASE}/delivery-times`;
  try {
    const response = await axios.get(url);
    const data = await response.data;
    let details = [];
    for (let i = 0; i < data.length; i++) {
      let new_details = {
        id: data[i].id,
        name: data[i].name
      };
      details.push(new_details);
    }
    dispatch(getDeliveryTime(details));
  } catch (error) {
    console.log(error);
  }
};

export const handle_package_size = () => async (dispatch) => {
  let url = `${ORDER_BASE}/package-sizes`;
  try {
    const response = await axios.get(url);
    const data = await response.data;
    let details = [];
    for (let i = 0; i < data.length; i++) {
      let new_details = { id: data[i].id, name: data[i].title };
      details.push(new_details);
    }
    dispatch(getPackageSize(details));
  } catch (error) {
    console.log(error);
  }
};

export const create_client_order = (pageRequest) => async (dispatch) => {
  let url = `${ORDER_BASE}/orders`;
  try {
    const response = await axios.post(url, pageRequest, axiosConfig);
    const data = response.data;
    dispatch(createClientOrder(data));
    dispatch(OrderStatus(SUCCESS));
    dispatch(get_my_analytics());
  } catch (error) {
    dispatch(OrderStatus(FAILED));
  }
};
export const redeem_coupon = (pageRequest) => async (dispatch) => {
  let url = `${ORDER_BASE}/coupons/redeem`;
  try {
    const response = await axios.post(url, pageRequest, axiosConfig);
    const data = response.data;
    dispatch(redeemCoupon(data));
    dispatch(getCouponStatus(SUCCESS))
  } catch (error) {
    let res = error.response.data;
    dispatch(redeemCoupon(res));
    dispatch(getCouponStatus(FAILED))

  }
};
export const get_my_analytics = () => async (dispatch) => {
  let url = `${ORDER_BASE}/order-analytics/my-analytics`;
  try {
    const response = await axios.get(url, axiosConfig);
    const data = response.data;
    let info = { ...data, status: SUCCESS };
    dispatch(getMyAnalytics(info));
  } catch (error) {
    let info = { status: FAILED };
    dispatch(getMyAnalytics(info));
  }
};
export const get_orders = (pageRequest)=> async(dispatch)=>{
  let type = pageRequest.type ? pageRequest.type : "";
  let rows = pageRequest.pageSize ? pageRequest.pageSize : "10"
  let page = pageRequest.current ? pageRequest.current : "1"
  let url = `${ORDER_BASE}/orders/my-orders?s=${type}&rows=${rows}&page=${page}`;
   try {
      let response = await axios.get(url, axiosConfig);
      let data = response.data;
      let orderList = {
          data: data.data,
          pagination:{
            current: data.meta.current_page,
            pageSize: Number(data.meta.per_page),
            total: data.meta.total,
          },
          status: SUCCESS
      }
      dispatch(getOrderList(orderList))
    //   return data;
    } catch (error) {
      let err = {
        status: FAILED
      }
      dispatch(getOrderList(err))

    }
  }
  export const track_order = (pageRequest)=> async(dispatch)=>{
    let url = `${ORDER_BASE}/tracking/${pageRequest}`;
     try {
        let response = await axios.get(url);
        let data = response.data
        if(data.status){
          let info = {
            ...data,
            actionStatus: SUCCESS
          }
          dispatch(trackOrders(info))
        }
      } catch (error) {
        let err = { actionStatus: FAILED}
          if(error && error.response){
            err = {
              ...err,
              ...error.response.data
            }
          }
          dispatch(trackOrders(err))
  
      }
    }

    export const update_order_with_payment = (pageRequest, onFinish)=> async(dispatch)=>{
      let url = `${ORDER_BASE}/orders/update-payment`;
       try {
          let response = await axios.put(url, pageRequest, axiosConfig);
          let data = response.data
            let info = {
              ...data,
              status: SUCCESS
            }
            onFinish && onFinish()
            dispatch(updateOrderWithPayment(info))
        
        } catch (error) {
          let err = {
            status: FAILED,
            ...error.response.data
          }
          dispatch(updateOrderWithPayment(err))
    
        }
      }
      export const check_price = (pageRequest) => async (dispatch) => {
      let checkPriceData = constructCheckPriceData(pageRequest)
      let url = `${ORDER_BASE}/orders/calculate-price`;
      try {
        let response = await axios.post(url, checkPriceData, axiosConfig);
        let data = currencyFormatter(response.data);
        let info = {
          ...data,
          status: SUCCESS
        }
        dispatch(handleCheckPrice(pageRequest));
        dispatch(client_order_bills(info))
      } catch (error) {
        let err = {
          status:FAILED
        }
        dispatch(client_order_bills(err))

      }

      };
      export const cancel_order = (pageRequest, onFinish)=> async(dispatch)=>{
        let url = `${ORDER_BASE}/orders/cancel`;
         try {
            let response = await axios.post(url, pageRequest, axiosConfig);
            let data = response.data;
           if(data.message){
            messageNotificationSuccess(data.message, "Success")
            onFinish && onFinish()
           }
          //   return data;
          } catch (error) {
            let res = error.response
            if(res && res.data.message){
              messageNotificationV2(res.data.message, 'Failed')
            }
      
          }
        }