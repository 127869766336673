import * as actiontypes from '../../actionTypes'

export const fetchWalletBalance = (balance) => {
    return {
      type: actiontypes.FETCH_WALLET_BALANCE,
      payload: balance
    };
  };
  export const initiatePayment = (amount) => {
    return {
      type: actiontypes.INITIATE_PAYMENT,
      payload: amount
    };
  };
  export const walletPaymentDetails = (details) => {
    return {
      type: actiontypes.CONFIRM_PAYMENT,
      payload: details
    };
  };
  export const getWalletHistory = payload => {
    return {
      type: actiontypes.GET_WALLET_HISTORY,
      payload
    };
  };
  export const orderPayment = payload => {
    return {
      type: actiontypes.ORDER_PAYMENT_VIA_WALLET,
      payload
    };
  };
  export const transactionLoading = payload => {
    return {
      type: actiontypes.TRANSACTION_LOADING,
      payload
    };
  };