// export const BASE_URL = "https://tapi.hexlogistics.ng/auth/v1/hexlogistics";
export const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}`;
export const ORDER_BASE_URL = `${process.env.REACT_APP_ORDER_BASE_API_URL}`;
export const NOTIFICATION_BASE_URL = `${process.env.REACT_APP_NOTIFICATION_BASE_API_URL}`;
export const MERCHANT_NAME = `${process.env.REACT_APP_MERCHANT_NAME}`
export const MERCHANT_BASE_URL = `${BASE_URL}/v1/${MERCHANT_NAME}`;
export const MAPS_URL = "https://maps.googleapis.com/maps/api";
export const ORDER_BASE = `${ORDER_BASE_URL}/v1/${MERCHANT_NAME}`;
export const PROFILE_BASE = `v1/${MERCHANT_NAME}/user/profile`;
export const USER_INFO = "USER_INFO";
export const SCHEDULED = "scheduled";
export const IMMEDIATE = "immediate";
export const SUCCESS = "success";
export const FAILED = "failed";
export const PICKUP_ADDRESS = "pickup_address";
export const PICKUP_RAW_RESPONSE = "pickup_raw_response";
export const DESTINATION_ADDRESS = "destination_address";
export const DESTINATION_RAW_RESPONSE = "destination_raw_response";
export const COUPON_PERCENTAGE = "percentage";
export const COUPON_VALUE = "value";
export const INCOMPLETE_ORDER = "incomplete";
export const PENDING_ORDER = "active";
export const COMPLETED_ORDER = "completed";
export const userInfo = JSON.parse(localStorage.getItem(USER_INFO)) || {}
export const token = userInfo.token || "";
export const onboardStatus = !!userInfo.onboardStatus|| false;
export const USER_ID = userInfo.id || "";
export const PAYSTACK = "paystack";
export const FLUTTER = "flutter";
export const WALLET = "wallet";
export const CASH = "cash";
export const ORDER_PAYMENT = "ORDER_PAYMENT";
export const WALLET_TRANSACTION = "WALLET_TRANSACTION";
export const MENU_KEYS = {
  DASHBOARD: "1",
  ORDERS: "2",
  WALLET: "3",
  NOTIFICATION: "4",
  PROFILE: "5"
}
export const headerTitles = {
  DASHBOARD: "Dashboard Overview",
  ORDERS: "Order",
  WALLET: "Wallet",
  NOTIFICATION: "Notification",
  PROFILE: "Profile",
  TRACK_ORDERS: "Track Orders",
  SEND_PACKAGE: "Send a Package",
  CHECK_PRICE: "Check Prices"
}

export const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };

export const PLATFORM = "web"
