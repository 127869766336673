// create order
export const GET_SENDER_DETAILS = 'GET_SENDER_DETAILS';
export const GET_RECEIVER_DETAILS = "GET_RECEIVER_DETAILS";
export const ORDER_FORM_STEP = "ORDER_FORM_STEP";
export const HANDLE_EDIT = "HANDLE_EDIT";
export const DELIVERY_TYPE = "DELIVERY_TYPE";
export const DELIVERY_TIME = "DELIVERY_TIME";
export const PACKAGE_SIZE = "PACKAGE_SIZE";
export const CREATE_CLIENT_ORDER = "CREATE_CLIENT_ORDER";
export const CLIENT_ORDER_STATUS = "CLIENT_ORDER_STATUS";
export const CLIENT_ORDER_BILLS = "CLIENT_ORDER_BILLS";
export const REDEEM_COUPON = "REDEEM_COUPON";
export const COUPON_STATUS = "COUPON_STATUS";
// order payment
export const UPDATE_ORDER_WITH_PAYMENT = "UPDATE_ORDER_WITH_PAYMENT"
// get order
export const GET_ORDER_LIST = "GET_ORDER_LIST";
// track order
export const GET_TRACKING_ID = "GET_TRACKING_ID";
export const TRACK_ORDER = "TRACK_ORDER";
// check price
export const CHECK_PRICE = "CHECK_PRICE";
export const CHECK_PRICE_STATUS = "CHECK_PRICE_STATUS";
// my analytics
export const MY_ANALYTICS = "MY_ANALYTICS";
// wallet
export const FETCH_WALLET_BALANCE = "FETCH_WALLET_BALANCE";
export const INITIATE_PAYMENT = "INITIATE_PAYMENT";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const GET_WALLET_HISTORY = "GET_WALLET_HISTORY";
export const ORDER_PAYMENT_VIA_WALLET = "ORDER_PAYMENT_VIA_WALLET";
export const TRANSACTION_LOADING = "TRANSACTION_LOADING";



// authentication
export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const ERROR_MESSAGE = "REGISTER_MESSAGE";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const REQUEST_PASSWORD_UPDATE = "REQUEST_PASSWORD_UPDATE";
export const RESEND_OTP = "RESEND_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const NEWSLETTER_SUBSCRIPTION = "NEWSLETTER_SUBSCRIPTION";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const CONFIRM_LOGIN = "CONFIRM_LOGIN"
export const LOGOUT = "LOGOUT"


// profile
export const UPDATE_PROFILE_IMG = "UPDATE_PROFILE_IMG"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const GET_PROFILE = "GET_PROFILE"
export const LOADING = "LOADING"

// notification
export const GET_NOTIFICATION = "GET_NOTIFICATION"