import React, { Component } from "react";
import { Form, Radio, Card,  Button, Row, Col } from "antd";
import Shippingfeesdetails from "./ShippingFeesDetails";
import Coupon from "./coupon";
import { FLUTTER, PAYSTACK, WALLET, ORDER_PAYMENT, USER_ID, CASH } from "../../../../common/constants";
import PaystackButton from "../../../../common/payment/paystack";
import WalletButton from '../../../../common/payment/wallet'
import CashButton from '../../../../common/payment/cash'
import { connect } from "react-redux";
import { initiate_payment, wallet_payment } from "../../../../common/actions/wallet/walletDispatchers";
import { convertStringToNumber } from "../../../../common/utils";

class MakePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMode: null,
      payWithPaystack: false,
      loading: false,
      cashData: null
}
  }
  
  handleChanges = (e) => {
    let value = e.target.value
      this.setState({
          paymentMode: value,
      })
  }
loader = (loading) =>{
  this.setState({loading})
}
  onFinish = () => {
    if(this.state.paymentMode === PAYSTACK){
      this.paystackPayment()
    } 
    if(this.state.paymentMode === WALLET){
    this.walletPayment()
    } 
    if(this.state.paymentMode === CASH){
      this.cashPayment()
      } 
  };

    paystackPayment = async ()=>{
      this.loader(true)
      const {initiate_payment, order, clientBills} = this.props
      const data = {
        transaction_id: order.reference,
        amount: convertStringToNumber(clientBills.total),
        order_id: order.order_id
      }
      await initiate_payment(data, ORDER_PAYMENT)
      this.setState({payWithPaystack: true})

    }

    walletPayment = async () =>{
      this.loader(true)
      const {wallet_payment, order, clientBills} = this.props
      const data = {
        pageRequest:{
          transaction_id: `${order.reference}`,
          amount: `${convertStringToNumber(clientBills.total)}`,
          user_id: `${USER_ID}`,
          title: `Payment for order (${order.tracking_id})`
        },
        order_id: order.order_id
      }
      await wallet_payment(data)
    }

    cashPayment =  () =>{
      this.loader(true)
      const {order, clientBills} = this.props
      const cashData = {
          reference_number: `${order.reference}`,
          price: `${convertStringToNumber(clientBills.total)}`,
          payment_method: CASH,
          order_id: order.order_id
      }
      this.setState({cashData})
    }
  render() {
    const {paymentMode, payWithPaystack} = this.state
    const paymentGateway = {
      paystack: {
        enabled: payWithPaystack,
        paymentType: ORDER_PAYMENT
      }
    }
    const orderPaymentGateway = JSON.parse(process.env.REACT_APP_PAYMENT_GATEWAY)
    
    return (
      <Row className="pRow">
        <Col lg={12} md={12} xm={24} sm={24} xs={24} className="order2 h80">
          <Form name="register" layout="vertical" onFinish={this.onFinish}>
            <Form.Item name="paymentMode" label="Choose payment Method">
              {/* <p>Payment Mode</p> */}
              <Radio.Group name="radiogroup" className="radios" onChange={this.handleChanges}>
                <Row>
                 { orderPaymentGateway.wallet && <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {" "}
                    <Radio
                      value={WALLET}
                      style={{ marginRight: "15px", marginBottom: "20px" }}
                    >
                      <Card
                        className="mdle pmode"
                      >
                        <div>
                          <img
                            src={`${process.env.REACT_APP_IMAGE}/wallet.png`}
                            style={{ height: "30px" }}
                            alt="paystack"
                          />
                          <p>Wallet</p>
                        </div>
                      </Card>
                    </Radio>
                  </Col>
                  }
                  { orderPaymentGateway.flutter && <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Radio value={FLUTTER}>
                      <Card
                        className="mdle pmode"
                      >
                        <div>
                          <img
                            src={`${process.env.REACT_APP_IMAGE}/flutter.png`}
                            style={{ height: "30px" }}
                            alt="flutter"
                          />
                          <p>Flutter</p>
                        </div>
                      </Card>
                    </Radio>
                  </Col>
  }
                {/* </Row>

                <Row> */}
                  { orderPaymentGateway.paystack && <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {" "}
                    <Radio
                      value={PAYSTACK}
                      style={{ marginRight: "15px", marginBottom: "20px" }}
                    >
                      <Card
                        className="mdle pmode"
                      >
                        <div>
                          <img
                            src={`${process.env.REACT_APP_IMAGE}/paystack.png`}
                            style={{ height: "30px" }}
                            alt="paystack"
                          />
                          <p>Paystack</p>
                        </div>
                      </Card>
                    </Radio>
                  </Col>
  }
                   { orderPaymentGateway.cash && <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {" "}
                    <Radio value={CASH}>
                      <Card
                        className="mdle pmode"
                      >
                        <div>
                          <img
                            src={`${process.env.REACT_APP_IMAGE}/money.png`}
                            style={{ height: "30px" }}
                            alt="flutter"
                          />
                          <p>Cash on Pickup</p>
                        </div>
                      </Card>
                    </Radio>
                  </Col>
  }
                </Row>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="coupon" className="w-90">
              <Coupon/>
            </Form.Item>
            <Form.Item shouldUpdate>
              {({ getFieldsValue, getFieldsError }) => {
                let { paymentMode } = getFieldsValue();
                getFieldsError();
                const formIsComplete = !!paymentMode;
                return (
                  <Button
                    // onClick={this.props.handleOk}
                    htmlType="submit"
                    className=""
                    style={{
                      backgroundColor: "black !important",
                    }}
                    loading={this.state.loading}
                    disabled={
                      !formIsComplete ||
                      !!getFieldsError().filter(({ errors }) => errors.length)
                        .length
                    }
                  >
                    Make payment
                  </Button>
                );
              }}
            </Form.Item>
          </Form>
          { paymentMode === PAYSTACK && <PaystackButton payWithPaystack={paymentGateway.paystack} loader={this.loader}/>}
          { paymentMode === WALLET && <WalletButton loader={this.loader}/>}
          { paymentMode === CASH && <CashButton loader={this.loader} data={this.state.cashData}/>}

        </Col>
        <Shippingfeesdetails clientBills={this.props.clientBills}/>
      </Row>
    );
  }
}

const mapStateToProps = ({orderDetails, wallet}) => {
  return {
    clientBills: orderDetails.clientBills,
    walletBalance: wallet.walletBalance
  };
};
export default connect(mapStateToProps, {initiate_payment, wallet_payment})(MakePayment);