import { fetchWalletBalance, initiatePayment, walletPaymentDetails, getWalletHistory, orderPayment, transactionLoading } from "./walletActions";
import { MERCHANT_BASE_URL, axiosConfig, FAILED, SUCCESS, WALLET_TRANSACTION, ORDER_PAYMENT  } from "../../constants";
import axios from "axios";


// fetch wallet balance
export const fetch_balance = () => async (dispatch) => {
    let url =  `${MERCHANT_BASE_URL}/fetch-balance`
    try {
      const response = await axios.get(url, axiosConfig);
      const data = response.data;
      let info = {
          ...data,
          status: SUCCESS
      }
      dispatch(fetchWalletBalance(info))
    }
    catch (error) {
        let info = {
            status: FAILED
        }
        dispatch(fetchWalletBalance(info))
    }
  };
  // initiate payment
  export const initiate_payment = (pageRequest, type) => async (dispatch) => {
    let url =  `${MERCHANT_BASE_URL}/initiate-payment`
    try {
     if(type === WALLET_TRANSACTION){
    dispatch(transaction_loading(true))
     const response = await axios.post(url, pageRequest, axiosConfig);
      const data = response.data;
     if(data.status){
      let info = {
       ...data.data,
       amount: pageRequest.amount
    }
    dispatch(initiatePayment(info))
     }
      }
      if(type === ORDER_PAYMENT){
      dispatch(initiatePayment(pageRequest))
      }
    }
    catch (error) {
        let info = {
            status: FAILED
        }
        dispatch(initiatePayment(info))

    }
  };

  // confirm_payment
  export const confirm_payment = (pageRequest) => async (dispatch) => {
    let url =  `${MERCHANT_BASE_URL}/confirm-payment`
    try {
      const response = await axios.post(url, pageRequest, axiosConfig);
      const data = response.data;
     if(data.status){
       let info = {
         ...data,
         status: SUCCESS
       }
    dispatch(walletPaymentDetails(info))
    dispatch(fetch_balance())
     }
    dispatch(get_wallet_history())
    }
    catch (error) {
        let info = {
            status: FAILED
        }
        dispatch(walletPaymentDetails(info))

    }
  };

  // get wallet history
  export const get_wallet_history = (pageRequest) => async (dispatch) => {
    let pageSize = pageRequest &&  pageRequest.pageSize ? pageRequest.pageSize : 6;
    let page = pageRequest && pageRequest.page ? pageRequest.page : 1
    let url =  `${MERCHANT_BASE_URL}/wallet-history?rows=${pageSize}&page=${page}`
    try {
      const response = await axios.get(url, axiosConfig);
      const data = response.data;
    
       let info = {
        data: data.data,
        pagination: data.pagination,
         status: SUCCESS
       }
    dispatch(getWalletHistory(info))
    }
    catch (error) {
        let info = {
            status: FAILED
        }
        dispatch(getWalletHistory(info))
    }
  };


  // pay for order via wallet
  export const wallet_payment = ({pageRequest, order_id}) => async (dispatch) => {
    let url =  `${MERCHANT_BASE_URL}/payment-via-wallet`
    try {
      const response = await axios.post(url, pageRequest, axiosConfig);
      const data = response.data;
      // let info;
      if(data.status){
         let  info = { message: data.message,  status: SUCCESS, order_id, ...pageRequest}
        dispatch(orderPayment(info))
      }
      dispatch(get_wallet_history())
    //  else{
    //   info = { message: data.message, status: FAILED }
    //  }

    }
    catch (error) {
       console.log(error.response);
    }
  };

export const transaction_loading = (pageRequest) => (dispatch) =>{
  dispatch(transactionLoading(pageRequest))
}