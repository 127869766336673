import React from "react";

const Loginfmtxt = (props) => {
  return (
    <div className="py1">
      <h3 className="h1s">{props.Details.heading}</h3>
      <span className="grytxt fmp">{props.Details.paragraph}</span>
    </div>
  );
};

export default Loginfmtxt;
