import React, { Component } from "react";
import { Row, Col, Form, Input, Button } from "antd";
import Loginfmtxt from "../component/Loginfmtxt";
import { Link, withRouter } from "react-router-dom";
import Loginbanner from "../component/Loginbanner";
import { connect } from "react-redux";
import {password_reset_request }from '../../../common/actions/authentication'
import { authMessageNotification } from "../../../common/utils";

class RequestPasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }
  
  loader = (loading) =>{
    this.setState({
      loading
    })
  }
  onFinish = async (values) => {
   this.loader(true)
    const { password_reset_request } = this.props
    const { passwordValue } = values
    let regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let regexPhone = /^0\d{10}$/;
    if (passwordValue.match(regexEmail)) {
      let request = {
        value: {email: passwordValue},
        via: 'email'
      };
      await password_reset_request(request);
    } else if (passwordValue.match(regexPhone)) {
       let request = {
         value: {phone: passwordValue},
         via: 'phone'
       };
      await password_reset_request(request);
    }
    else {
      let err = { message: "value should be email or phone!"}
      authMessageNotification(err);
      return this.loader(false)
    }
    this.checkStatus()
  };
  checkStatus = () => {
    this.loader(false)
    const { data } = this.props;
    if (data?.status===true) {
    this.props.history.push("/instruction");
    }
    else {
      authMessageNotification(data)
    }
  }
  render() {
    const details = {
      heading: "Recover Password",
      paragraph: "Fill the details below to recover your password",
    };
    return (
      <div className="lforms">
        <Row>
          <Loginbanner />
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="fmcol containers"
          >
            <img src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`} alt="logo" className="logoslog" />
            <div className="h50  ">
              <Loginfmtxt Details={details} />
              <Form
                name="basic"
                onFinish={this.onFinish}
                className="lgfms"
              >
                <Form.Item
                  name="passwordValue"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message:
                  //       "Please enter your Mobile Number eg(09099383773)",
                  //     pattern: new RegExp(/^0\d{10}$/),
                  //   },
                  // ]}
                >
                  <Input className="inp w-90" placeholder="Phone Number or email address" />
                </Form.Item>
                <Form.Item className="pt4" shouldUpdate>
                  {({ getFieldsError, getFieldsValue }) => {
                    const { passwordValue } = getFieldsValue();
                    const isFormComplete = !! passwordValue
                    getFieldsError();
                    return (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="regbtn"
                        loading={this.state.loading}
                        disabled={
                          !isFormComplete || !!getFieldsError().filter(
                            ({ errors }) => errors.length
                          ).length
                        }
                        block
                      >
                        Update Password
                      </Button>
                    );
                  }}
                </Form.Item>
              </Form>
              <div className="tcent fmpr py1">
              <span>Don't have an Account? &nbsp;</span>
              <Link
                to="/register"
                style={{ color: "#6BAF43", textDecoration: "underline" }}
              >
                Register for one now
              </Link>
            </div>
            </div>

            
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { userLogin } = state;
  return {
    data: userLogin.requestNewPassword,
  };
}
export default connect(mapStateToProps, { password_reset_request })(withRouter(RequestPasswordChange));
