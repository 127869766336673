import React from 'react';
import { connect } from 'react-redux';
import { update_order_with_payment } from '../../actions/order/orderActionDispatch';
import { messageNotificationSuccess } from '../../utils';

class Index extends React.Component {
componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
        this.updatePayment()
    }
}
updatePayment = () =>{
    const {data, loader, update_order_with_payment} = this.props
         update_order_with_payment(data, ()=>{
            messageNotificationSuccess("Success! Pay cash on delivery",  "Order payment" )
            loader(false)
         })
      
}
    render() {
        return false
    }
}

export default connect(null, {update_order_with_payment})(Index);
