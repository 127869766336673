import React, { Component } from "react";
import { Col } from "antd";
import { connect } from "react-redux";
import { COUPON_PERCENTAGE, COUPON_VALUE} from "../../../../common/constants";
import { currencyFormatterV2 } from "../../../../common/utils";
class Shippingfeesdetails extends Component {
 
  render() {
    const { couponData, clientBills } = this.props;
    return (
      <Col
        lg={12}
        md={12}
        xm={24}
        sm={24}
        xs={24}
        style={{ backgroundColor: " #F3F3F3" }}
        className="pad mt2 maxHeight"
      >
        <div className="height-pay">
          <div className="flex spacebtw">
            <p>Shipping Fee</p>
            <p>
              {clientBills.shipping_fee
                ? `NGN ${clientBills.shipping_fee}`
                : "NGN 0.00"}{" "}
            </p>
          </div>
          <div className="flex spacebtw">
            <p>Bonus </p>
            <p>{clientBills.bonus ? 
            `NGN ${currencyFormatterV2(clientBills.price_effect) }` 
            : "NGN 0.00"}</p>
          </div>
          <div className="flex spacebtw">
            <p>
              Coupon
              {couponData &&
                (couponData.coupon.calculated_by === COUPON_PERCENTAGE
                  ? `(${couponData.coupon.amount}% off)`
                  : couponData.coupon.calculated_by === COUPON_VALUE
                  ? `(NGN${couponData.coupon.amount} off)`
                  : "")}
            </p>
            <p>{couponData && clientBills.coupon ? `NGN ${clientBills.coupon}` : "NGN 0.00"}</p>
          </div>
        </div>
        <div className="flex spacebtw totalFee">
          <p>
            <b>Total</b>
          </p>
          <p>
            <b>{clientBills.total ? `NGN ${clientBills.total}` : "NGN 0.00"}</b>
          </p>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = ({ orderDetails }) => {
  return {
    couponData: orderDetails.coupon ? orderDetails.coupon.price : ""
  };
};
export default connect(mapStateToProps, {})(Shippingfeesdetails);
