import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Spin } from "antd";

function AuthBasePage({
  children,
  requestNewPassword,
  otpVerification,
  page = "recoverPassword",
}) {
  const [loading, setLoading] = React.useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 400);

  return page === "login" || page === "register" ? (
    <Spin spinning={loading}>
      <div>{children}</div>
    </Spin>
  ) : requestNewPassword.id || otpVerification.otp ? (
    { ...children }
  ) : (
    <Redirect to={"/login"} />
  );
}

const mapStateToProps = (state) => {
  const { userLogin } = state;
  return {
    requestNewPassword: userLogin.requestNewPassword || {},
    otpVerification: userLogin.otpVerification || {},
  };
};
export default connect(mapStateToProps, {})(AuthBasePage);
