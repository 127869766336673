import { message } from "antd";
import { userInfo, SUCCESS } from "../constants";
import { notification } from "antd";
import { WarningOutlined, InfoCircleOutlined, ExclamationOutlined } from "@ant-design/icons";

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = imageValidationType(file);
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
    return;
  }
  const isLt2M = imageValidationSize(file);
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
    return;
  }
  return isJpgOrPng && isLt2M;
};

export const imageValidationSize = (img)=>{
  const isLt2M = img.size / 1024 / 1024 < 2;
  return isLt2M
}

export const imageValidationType = (img)=>{
  const isJpgOrPng = img.type === "image/jpeg" || img.type === "image/png";
  return isJpgOrPng
}

// export const convertStringToDateFormat = (str) => {
//   let date = new Date(str);
//   let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
//   let day = ("0" + date.getDate()).slice(-2);
//   let hours = ("0" + date.getHours()).slice(-2);
//   let minutes = ("0" + date.getMinutes()).slice(-2);
//   let seconds = ("0" + date.getSeconds()).slice(-2);
//   const myTime = [hours, minutes, seconds].join(":");
//   const myDate = [date.getFullYear(), mnth, day].join("-");
//   return [myDate, myTime].join(" ");
// };

// export const convertStringToRequiredDateFormat = (str) => {
//   let my_date = new Date(str).toLocaleString().replace(",", "");
//   let regex = new RegExp("/", "g");
//   return my_date.replace(regex, "-");
// };
export const convertStringToNumber = (str) => {
  let regex = new RegExp(",", "g");
  let num = str.replace(regex, "");
  return Number(num);
};
export const constructOrder = (data) => {
  const constructedData = {
    delivery_type_id: Number(data.delivery_type_id),
    delivery_time_id: Number(data.delivery_time_id),
    package_size_id: Number(data.package_size_id),
    sender_name: data.sender_name,
    sender_phone_number: data.sender_phone_number,
    receiver_name: data.receiver_name,
    receiver_phone_number: data.receiver_phone_number,
    pickup_type: data.pickup_type,
    pickup_time: data.pickup_time
      ? data.pickup_time
      : null,
    item_description: data.item_description,
    additional_info: data.additional_info || null,
    pickup_lat: data.pickup_raw_response.results[0].geometry.location.lat,
    pickup_long: data.pickup_raw_response.results[0].geometry.location.lng,
    pickup_address: data.pickup_address,
    pickup_raw_response: data.pickup_raw_response,
    destination_lat:
      data.destination_raw_response.results[0].geometry.location.lat,
    destination_long:
      data.destination_raw_response.results[0].geometry.location.lng,
    destination_address: data.destination_address,
    destination_raw_response: data.destination_raw_response
  };
  return constructedData;
};
export const constructCheckPriceData = (data) => {
  const checkPriceData = {
    sender_id: userInfo.id,
    delivery_type_id: Number(data.delivery_type_id),
    delivery_time_id: Number(data.delivery_time_id),
    package_size_id: Number(data.package_size_id),
    pickup_lat: data.pickup_raw_response.results[0].geometry.location.lat,
    pickup_long: data.pickup_raw_response.results[0].geometry.location.lng,
    pickup_address: data.pickup_address,
    pickup_raw_response: data.pickup_raw_response,
    destination_lat:
    data.destination_raw_response.results[0].geometry.location.lat ?  data.destination_raw_response.results[0].geometry.location.lat : "",
    destination_long:
    data.destination_raw_response.results[0].geometry.location.lng ? data.destination_raw_response.results[0].geometry.location.lng : "",
    destination_address: data.destination_address,
    destination_raw_response: data.destination_raw_response
  };
  return checkPriceData;
};
export const currencyFormatter = (price) => {
  if (price) {
    let shipping_fee;
    let total;
    let coupon;
    let bonus;

    if (price.shipping_fee) {
      shipping_fee = Intl.NumberFormat().format(price.shipping_fee);
    }
    if (price.total) {
      total = Intl.NumberFormat().format(price.total);
    }
    if (price.coupon) {
      let { price_effect } = price.coupon;
      coupon = Intl.NumberFormat().format(price_effect);
    }
    if (price.bonus) {
      let { amount } = price.bonus;
      bonus = Intl.NumberFormat().format(amount);
    }
    let fee = {
      shipping_fee,
      total,
      coupon,
      bonus
    };
    return fee;
  }
};
export const currencyFormatterV2 = (price) => {
  if (price) {
    let currencyFormat = new Intl.NumberFormat("en",
      {  maximumFractionDigits: 2, 
      minimumFractionDigits: 2
    }
    )
    let amount = currencyFormat.format(price);
    return amount;
  }
};
// error notification
export const messageNotification = (info, status) => {
  if(status && status === SUCCESS){
  message.success(info);
  }
  else{
    message.error(info);
  }
};

// error notification v2
export const messageNotificationV2 = (info, title) => {
  let err = title? title : "Error"
  notification.open({
    message: err,
    description: <div className="small">{info}</div>,
    icon: <WarningOutlined style={{ color: "red" }} />,
    className: "notify"
  });
};

// success notification v1
export const messageNotificationSuccess = (info, title) => {
  notification.open({
    message: title ? title : "Success",
    description: <div className="small">{info}</div>,
    icon: <InfoCircleOutlined color="green"/>,
    className: "notify"
  });
};
export const idGenerator = () => {
  let id = Math.random().toString().substr(2, 5);
  return id;
};
export const filterList = (searchWord, filterBy, data) => {
  let result = [];
  if (searchWord.trim() === "" || !searchWord) {
    data.map((data) => {
      return result.push(data);
    });
  }
  if (data && searchWord) {
    // by name
    if (filterBy === "Name") {
      function search(data) {
        return (
          data.receiver_info.receiver_name
            .toLowerCase()
            .indexOf(searchWord) !== -1
        );
      }
      data.filter(search).map((data) => {
        return result.push(data);
      });
    }
    // by address
    if (filterBy === "Address") {
      function search(data) {
        return (
          data.receiver_info.destination_address
            .toLowerCase()
            .indexOf(searchWord) !== -1
        );
      }
      data.filter(search).map((data) => {
        return result.push(data);
      });
    }
  }
  return result;
};

export const localStorageSave = (id, value) => {
  localStorage.setItem(`${id}`, JSON.stringify(value));
}
export const refreshPage =  ()=>{
  window.location.reload(true)
}

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const result = JSON.parse(jsonPayload);
  return result;
};

export const getSerialNumber = (pageNumber, index) =>{
  let  num = Number(pageNumber) * 10
  let  i = Number(index) + 1
  num = num + i;
  num = num - 10;
  return num;
}

export const validOrderStatus = (status) =>{
let string;
switch (status) {
  case "pending" || "incomplete": string = ` is ${status}`
    break;
    case "incomplete": string = ` is ${status}`
    break;
  case "in_transit": string = " is in transit"
      break;
  case "rider_to_pickup": string = " has been accepted for pickup"
  break
  case "completed"  :string = ` has been ${status}`
  break
  case  "cancelled"  :string = ` has been ${status}`
  break
  case  "accepted" :string = ` has been ${status}`
  break
  default: string = " is ongoing"
    break;
}
return string
}

// give notification
export const authMessageNotification = (info) => {
    let email = info?.email ? info.email : "";
    let phone = info?.phone ? info.phone : "";
    let message = info?.message ? info.message : "An error occured";
    notification.open({
        message: `${message}`,
        description: (
            <div className="small">
                <p>{email} </p> <p>{phone}</p>
            </div>
        ),
        icon: <ExclamationOutlined className="exc" />,
        className: "notify",
    });
}
// check otp
 export const checkOtp = (type, otpVerification, history, callback) => {
   if (otpVerification.status === true) {
     if (type === "onboard_new_user") {
         localStorageSave("onboardStatus", true)
         history.push("/success");
     } else if (type === "reset_password") {
         history.push("/update-password");
     }  
     return messageNotificationSuccess(otpVerification.message)
   } else {
     callback()
     return messageNotificationV2(otpVerification.message);
   }
 };

 export const truncateString = (str, len = 10) => {
  return str.length > len ? `${str.substring(0, len)}..` : str
 }

//  const matchMediaQuery = (breakPoints, setBreakpoint) =>{
//   for(var key of Object.keys(breakPoints)){
//       if(window.matchMedia(`${breakPoints[key]}`).matches){
//           setBreakpoint(key);
//       }
//   }
//   }
  
  // export default function BreakpointObs(breakPoint, setBreakpoint){
  //     matchMediaQuery(breakPoint, setBreakpoint);
  //     window.addEventListener("resize", ()=>{
  //         matchMediaQuery(breakPoint, setBreakpoint)
  //     })
  // }
