import * as actiontypes from "../actionTypes";

const initialState = {}

 const Profile = (state = initialState, action) =>{
    switch (action.type) {
        case actiontypes.UPDATE_PROFILE:{ 
            return { ...state, update: action.payload}
        }
        case actiontypes.UPDATE_PASSWORD:{ 
            return { ...state, password: action.payload}
        }
        case actiontypes.UPDATE_PROFILE_IMG:{ 
            return { ...state, profileImg: action.payload}
        }
        case actiontypes.GET_PROFILE:{ 
            return { ...state, user: action.payload}
        }
        case actiontypes.LOADING:{ 
            return { ...state, loading: !!action.payload}
        }
        default:
           return state;
    }
}

export default Profile