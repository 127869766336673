import React from "react";
import { Row, Col } from "antd";
import Loginfmtxt from "../component/Loginfmtxt";
import Otp from "../component/Otp";
import Loginbanner from "../component/Loginbanner";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthBasePage from "../component/BasePage";

class OtpWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
     details: {}
    }
  }
  
  componentDidMount(){
    this.getContact()
  }
  
 getContact =  () =>{
  //  console.log(data, data.email);
  const {requestNewPassword} = this.props
  let value = requestNewPassword && requestNewPassword.value ? requestNewPassword.value : {phone: "******"}
    let str =   value?.phone || value?.email
    let details = {
      heading: "Update Password",
      paragraph: `A code has been sent to ${str}`,
      txt: "Verify",
      type: 'reset_password'
    };
    this.setState({
      details
    })
  }
  
  render(){
  return (
    <AuthBasePage>
    <div className="lforms">
      <Row>
        <Loginbanner />
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="fmcol containers"
        >
          <img src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`} alt="logo" className="logoslog" />
          <Loginfmtxt Details={this.state.details} />

          <div className="h50 flex flex-column">
            <Otp details={this.state.details} getUserContact={this.getContact}/>
          </div>

          <div className="tcent fmpr">
          <span>Don't have an Account? &nbsp;</span>
          <Link to="/register" style={{ color: "#6BAF43", textDecoration: "underline" }}>
            Register for one now
          </Link>
        </div>
        </Col>
      </Row>
    </div>
    </AuthBasePage>
  );
  }
};

const mapStateToProps = ({userLogin}) => {
  console.log(userLogin);
  return {
    requestNewPassword: userLogin.requestNewPassword,
  };
}
export default connect(mapStateToProps, {})(OtpWrapper);

