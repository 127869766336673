import React from "react";
import { Row, Col } from "antd";
import Loginfmtxt from "../component/Loginfmtxt";
import Otp from "../component/Otp";
import Loginbanner from "../component/Loginbanner";

const VerifyAccount = () => {
  const details = {
    heading: "Verify Account",
    paragraph: "A code has been sent to your phone number",
    txt: "Verify",
    type: "onboard_new_user"
  };
  return (
    <div className="lforms">
      <Row>
        <Loginbanner />
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fmcol containers">
          <img src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`} alt="logo" className="logoslog" />
          <div className="h50">
            <Loginfmtxt Details={details} />
            <Otp details={details} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyAccount;
