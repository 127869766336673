import axios from 'axios';
import { MERCHANT_BASE_URL,  PLATFORM, USER_INFO } from '../../constants';
import { localStorageSave, parseJwt } from '../../utils';
import * as actionTypes from "../../actionTypes";

const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
// actions
export const registerUser = (userDetails) => {
  return {
    type: actionTypes.REGISTER_USER,
    payload: userDetails.data
  };
};
export const userLogin = (response) => {
  return {
    type: actionTypes.LOGIN_USER,
    payload: response.data
  };
};
export const getError = (error) => {
  return {
    type: actionTypes.ERROR_MESSAGE,
    payload: error
  };
};
export const getUserAuthentication = (response) => {
  return {
    type: actionTypes.AUTHENTICATE_USER,
    payload: response
  };
};
export const otpVerification = (response) => {
  return {
    type: actionTypes.OTP_VERIFICATION,
    payload: response,
  };
};
export const resendOtp = (response) => {
  return {
    type: actionTypes.RESEND_OTP,
    payload: response,
  };
};
export const requestPassword = (response) => {
  return {
    type: actionTypes.REQUEST_PASSWORD_UPDATE,
    payload: response,
  };
};
export const resetPassword = (payload) => {
  return {
    type: actionTypes.RESET_PASSWORD,
    payload
  };
};
export const newsletterSubscription = (payload) => {
  return {
    type: actionTypes.NEWSLETTER_SUBSCRIPTION,
    payload
  };
};
export const sendMessage = (payload) => {
  return {
    type: actionTypes.SEND_MESSAGE,
    payload
  };
};
export const confirmLogin = payload =>{
  return {
      type: actionTypes.CONFIRM_LOGIN,
      payload
    };
}
// dispatchers
// // Register
export const register_user = (pageRequest) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/signup?type=user&platform=${PLATFORM}`;
  
  const handleError = (data = {}) => {
    let any_error = {
      ...data,
      status: true,
      message: data.message || "An error occurred!",
    };
    dispatch(getError(any_error));
  };

  try {
    const response = await axios.post(url, pageRequest, axiosConfig);
    const data = response.data;

    if (data.status === true) {
      let info = data.data;
      let saveInfo = {
        id: info.id,
        full_name: info.full_name,
        email: info.email,
        phone: info.phone,
        profile_image: info.profile_image_url,
        language: info.language,
        token: info.token
      };
      localStorageSave(USER_INFO, saveInfo);
      localStorageSave("onboardStatus", false);

      // Set error status to false upon successful registration
      let error_no = {
        status: false,
        message: data.message
      };
      dispatch(getError(error_no));
      dispatch(registerUser(data));

      return;
    } else {
      handleError(data.data);
    }
  } catch (error) {
    // Properly handle errors and pass error response to handleError
    handleError(error.response ? error.response.data : {});
  }
};

// export const register_user = (pageRequest) => async (dispatch) => {
//   let url = `${MERCHANT_BASE_URL}/signup?type=user&platform=${PLATFORM}`;
//   const handleError = (data = {}) => {
//     let any_error = {
//       ...data,
//       status: true,
//       message: data.message || "An error occured!",
//     };
//     dispatch(getError(any_error));
//   }
//   try {
//     const response = await axios.post(url, pageRequest, axiosConfig)
//      const data = response.data
//       if (data.status === true) {
//         let info = data.data
//         let saveInfo = {
//           id: info.id,
//           full_name: info.full_name,
//           email: info.email,
//           phone: info.phone,
//           profile_image:info.profile_image_url,
//           language: info.language,
//           token:info.token
//         }
//         localStorageSave(USER_INFO, saveInfo);
//         localStorageSave("onboardStatus", false);
//          dispatch(registerUser(data));
//          let error_no = {
//            status: false,
//            message: data.message
//          };
//         dispatch(getError(error_no));
//          return
//        }
//       else {
//          handleError(data.data)
//      }
//   }
//    catch(error) {
//     handleError()
//     };
// };

// login
export const user_login = (pageRequest) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/login?platform=${PLATFORM}&type=user`;
  try {
  const response = await axios.post(url, pageRequest, axiosConfig)
        const data = response.data;
      if (data.status === true) {
        const logins = {
          status: data.status,
          message: data.message,
        };
        dispatch(userLogin(data));
        dispatch(getUserAuthentication(logins));
        let info = data.data
        let saveInfo = {
          id: info.id,
          full_name: info.full_name,
          email: info.email,
          phone: info.phone,
          profile_image:info.profile_image_url,
          language: info.language,
          token:info.token
        }
        localStorageSave(USER_INFO, saveInfo);
        localStorageSave("onboardStatus", true);

        return;
      } else {
        const error = {
          ...data.data,
          status: data.status,
          message: data.message
        };
        dispatch(getUserAuthentication(error));
      }
     }
    catch(error){
      if (error) {
        let message = error.response;
        let errors = {
          message: message.data.message,
          status: false,
        };
        dispatch(getUserAuthentication(errors));
      }
    };
 

}

// otp verification for onboarding
export const otp_verification = (pageRequest) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/verify-otp`;
  try {
    const response = await axios.post(url, pageRequest, axiosConfig);
    const data = response.data;
     let otpDetails = {
       status: data.status,
       message: data.message
     };
    if (data.status === true) {
      let info = {
        ...otpDetails,
        ...pageRequest
      }
          dispatch(otpVerification(info));
          return;
        }
      dispatch(otpVerification(otpDetails));
  }
  catch (error) {
    console.log(error.response);
  }
 
}
// otp verification for password reset
export const password_req_otp_verification = (pageRequest, type) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/password/reset?type=${type}`;
  try {
    const response = await axios.post(url, pageRequest, axiosConfig);
    const data = response.data;
     let otpDetails = {
       status: data.success,
       message: data.message
     };
    if (data.success === true) {
      let info = {
        ...otpDetails,
        ...pageRequest
      }
          dispatch(otpVerification(info));
          return;
        }
      dispatch(otpVerification(otpDetails));
  }
  catch (error) {
    console.log(error.response);
  }
 
}
// resendOtp
export const resend_otp = (pageRequest) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/resend-otp?type=phone`;
  try {
    const response = await axios.post(url, pageRequest, axiosConfig);
    const data = response.data;
    let details = {
      ...data.data,
      status: data.status,
      message: data.message,
    };
    dispatch(resendOtp(details));
  }
  catch (error) {
    console.log(error.response);
  }
 
}
// requestPassword
export const password_reset_request = (pageRequest) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/password/reset-request?via=${pageRequest.via}&type=user`;
  try {
    const response = await axios.post(url, pageRequest.value, axiosConfig);
    const data = response.data;
    let details = {
      ...data.data,
      status: data.status,
      message: data.message,
    };
    if (data.status === true) {
       let det = { ...data.data };
       let newData = {
         id: `${det.id}`,
         value: pageRequest.value,
         status: data.status,
         message: data.message,
         via: pageRequest.via
       };
      return dispatch(requestPassword(newData));
    }
    dispatch(requestPassword(details));
  }
  catch (error) {
    console.log(error.response);
  }
 
}

// resetPassword 
export const password_reset = (pageRequest, type) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/password/update-password?type=${type}`;
  try {
    const response = await axios.post(url, pageRequest, axiosConfig);
    const data = response.data;
    if (data.success || data.message) {
       dispatch(resetPassword({status: data.success, message: data.message}));
    }
  }
  catch (error) {}
}
// NEWSLETTER SUBSCRIPTION 
export const newsletter_subscription = (pageRequest) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/newsletter`;
  try {
    const response = await axios.post(url, pageRequest);
    const data = response.data;
    if (data.success || data.message) {
       dispatch(newsletterSubscription({status: data.success, message: data.message}));
    }
  }
  catch (error) {
    dispatch(newsletterSubscription({status: false}));
  }
}

// SEND MESSAGE 
export const send_message = (pageRequest) => async (dispatch) => {
  let url = `${MERCHANT_BASE_URL}/notification/api/send-mail`;
  try {
    const response = await axios.post(url, pageRequest);
    const data = response.data;
    if (data.success || data.message) {
       dispatch(sendMessage({status: data.success, message: data.message}));
    }
  }
  catch (error) {
    if(error){
      let info = {status: false, message: error.response.data}
    dispatch(sendMessage(info));
    console.log(info);
    }
  }
}

export const logout = () => dispatch =>{
  localStorage.clear()
  dispatch(confirmLogin(false))
  return window.location = "/login"
  }

  export const initApp = () => dispatch =>{
    const userInfo = JSON.parse(localStorage.getItem(USER_INFO)) || {}
    const token = userInfo.token || "";
    if(token){
    let parsedToken = parseJwt(token)
    let expiryTime = parsedToken.exp * 1000;
    let currentTime = Date.now()
    if (currentTime > expiryTime) {
      dispatch(logout());
    }
    }else{
        dispatch(logout())
    } 
}