import {
  BASE_URL,
  axiosConfig,
  FAILED,
  SUCCESS,
  PROFILE_BASE
} from "../../constants";
import axios from "axios";
import * as actionTypes from '../../actionTypes'

// actions
export const updateImg = (payload) => {
  return {
    type: actionTypes.UPDATE_PROFILE_IMG,
    payload
  };
};
export const updateProfile = (payload) => {
  return {
    type: actionTypes.UPDATE_PROFILE,
    payload
  };
};
  export const updatePassword = (payload) => {
      return {
        type: actionTypes.UPDATE_PASSWORD,
        payload
      };
};

export const getProfile = (payload) => {
  return {
    type: actionTypes.GET_PROFILE,
    payload
  };
};
export const loading = (payload) => {
return {
  type: actionTypes.LOADING,
  payload
};
};

// dispatchers
export const get_profile = () => async (dispatch) => {
  let url = `${BASE_URL}/${PROFILE_BASE}`;
  dispatch(loading(true))
  try {
    const response = await axios.get(url, axiosConfig);
    const data = response.data;
    if (data.status) {
      let info = {
        ...data.data,
        status: SUCCESS
      };
      dispatch(getProfile(info));
      dispatch(loading(false))
    }
  } catch (error) {
    let info = { status: FAILED };
    dispatch(getProfile(info));
    dispatch(loading(false))
  }
};
export const update_image = (pageRequest) => async (dispatch) => {
  let formData = new FormData();
  formData.append("profile_image", pageRequest);
    let url = `${BASE_URL}/${PROFILE_BASE}/image?type=user`;
    try {
      const response = await axios.post(url, formData, axiosConfig);
      const data = response.data;
      if (data.status) {
        let info = {
          message: data.message,
          status: SUCCESS
        };
        dispatch(updateImg(info));
        dispatch(get_profile());
      }
    } catch (error) {
      let info = { status: FAILED };
      dispatch(updateImg(info));
    }
  };
export const update_profile = (pageRequest) => async (dispatch) => {
  let url = `${BASE_URL}/${PROFILE_BASE}`;
  try {
    const response = await axios.post(url, pageRequest, axiosConfig);
    const data = response.data;
    if (data.status) {
      let info = {
        ...data.data,
        message: data.message,
        status: SUCCESS
      };
      dispatch(updateProfile(info));
      dispatch(get_profile());
    } else {
      let dataObj = { ...data.data };
      let phoneMessage = dataObj.phone ? dataObj.phone[0] : "";
      let emailMessage = dataObj.email ? dataObj.email[0] : "";
      let info = {
        message: <>{phoneMessage} <br/> {emailMessage}</>,
        status: FAILED
      };
      dispatch(updateProfile(info));
    }
  } catch (error) {
    let info = { status: FAILED };
    dispatch(updateProfile(info));
  }
};

export const update_password = (pageRequest) => async (dispatch) => {
    let url = `${BASE_URL}/v1/change-password`;
    try {
      const response = await axios.post(url, pageRequest, axiosConfig);
      const data = response.data;
      if (data.status) {
        let info = {
          message: data.message,
          status: SUCCESS
        };
        dispatch(updatePassword(info));
      } 
      else {
        let dataObj = { ...data.data };
        let currentPasswordMessage = dataObj.current_password ? "Old password is incorrect" : "";
        // let emailMessage = dataObj.email ? dataObj.email[0] : "";
        let info = {
          message: <>{currentPasswordMessage} <br/></>,
          status: FAILED
        };
        dispatch(updatePassword(info));
      }
    } catch (error) {
        let dataObj = { ...error.response.data };
        let currentPasswordMessage = dataObj.current_password ? "Old password is incorrect" : "";
      let info = { 
      message: <>{currentPasswordMessage} <br/></>,
      status: FAILED };
      dispatch(updatePassword(info));
    }
  };