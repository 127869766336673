
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { connect } from 'react-redux';
import { otp_verification, resend_otp, password_req_otp_verification} from '../../../common/actions/authentication';
import { checkOtp} from '../../../common/utils';

class Otp extends Component {
  otpRef = React.createRef()
  state = {
    resendOtp: false,
    loading: false
  }
  loader = (loading) =>{
    this.setState({
      loading
    })
  }
  onFinish = async (values) => {
    this.loader(true)
    const { details, otp_verification, user, requestNewPassword, password_req_otp_verification } = this.props;
    const { otpA, otpB, otpC, otpD, otpE, otpF } = values
    const otpNo = `${otpA}${otpB}${otpC}${otpD}${otpE}${otpF}`;
    if (details.type === "onboard_new_user") {
      if (user) {
        const { phone, id } = user;
        const allValues = {
          otp: otpNo,
          phone: phone,
          id: `${id}`
        };
        await otp_verification(allValues);
        this.verifyOtp();
      }

    } else if (details.type === "reset_password") {
      if(requestNewPassword && requestNewPassword.value){

      let requestType;
      if (requestNewPassword?.via === "email"){
        requestType = "email"

      } else if((requestNewPassword?.via === "phone")){
        requestType = "phone"
        }

        let req = {
          ...requestNewPassword.value,
          otp: otpNo
        }

      await password_req_otp_verification(req, requestType)
      return this.verifyOtp();

      }
    }

  };
  callback = () => {
    const { newOtpInfo, resend_otp, user, details, requestNewPassword } = this.props
    let phone = details.type === "onboard_new_user" ? user.phone : requestNewPassword.phone
    let time = 5 * 60 * 1000;
    let info = { phone }
    this.otpRef.current.resetFields()
    setTimeout(() => {
      resend_otp(info)
    }, time)
    if (newOtpInfo && newOtpInfo.status) {
      this.setState({
        resendOtp: true
      })
    }

  }
  verifyOtp = () => {
    this.loader(false)
    const { otpVerification, details, history } = this.props;
    checkOtp(details.type, otpVerification, history, this.callback)
  };

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  render() {
    return (
      <div>
        <div className="h50">
          <Form name="otp" className="pt4" onFinish={this.onFinish} ref={this.otpRef}>
            <div className="mds otp-box-wrap">
              <Form.Item name="otpA" className="inputn"
                rules={[
                  { message: "", required: true, pattern: new RegExp(/\d{1}$/) }
                ]}
              >
                <Input
                  autoComplete="off" tabIndex="1" maxLength="1" onKeyUp={(e) => this.inputfocus(e)}
                />
              </Form.Item>
              <Form.Item name="otpB" className="inputn"
                rules={[
                  { message: "", required: true, pattern: new RegExp(/\d{1}$/) }
                ]}
              >
                <Input
                  autoComplete="off" tabIndex="2" maxLength="1" onKeyUp={(e) => this.inputfocus(e)}
                />
              </Form.Item>
              <Form.Item name="otpC" className="inputn"
                rules={[
                  { message: "", required: true, pattern: new RegExp(/\d{1}$/) }
                ]}
              >
                <Input
                  autoComplete="off" tabIndex="3" maxLength="1" onKeyUp={(e) => this.inputfocus(e)}
                />
              </Form.Item>
              <Form.Item name="otpD" className="inputn"
                rules={[
                  { message: "", required: true, pattern: new RegExp(/\d{1}$/) }
                ]}
              >
                <Input
                  autoComplete="off" tabIndex="4" maxLength="1" onKeyUp={(e) => this.inputfocus(e)}
                />
              </Form.Item>
              <Form.Item name="otpE" className="inputn"
                rules={[
                  { message: "", required: true, pattern: new RegExp(/\d{1}$/) }
                ]}
              >
                <Input
                  autoComplete="off" tabIndex="5" maxLength="1" onKeyUp={(e) => this.inputfocus(e)}
                />
              </Form.Item>
              <Form.Item name="otpF" className="inputn"
                rules={[
                  { message: "", required: true, pattern: new RegExp(/\d{1}$/) }
                ]}
              >
                <Input
                  autoComplete="off" tabIndex="6" maxLength="1" onKeyUp={(e) => this.inputfocus(e)}
                />
              </Form.Item>
            </div>
            <Form.Item className="pt4 auth-submit-btn" shouldUpdate>
              {({ getFieldsValue, getFieldsError }) => {
                const { otpA, otpB, otpC, otpD, otpE, otpF } = getFieldsValue();
                getFieldsError();
                const formIsComplete = !!otpA && !!otpB && !!otpC && !!otpD && !!otpE && !!otpF;
                return (
                  <Button type="primary" htmlType="submit" className="regbtn" loading={this.state.loading}
                    disabled={!formIsComplete || !!getFieldsError().filter(({ errors }) => errors.length).length}
                    block
                  >
                    {this.props.details.txt}
                  </Button>
                );
              }}
            </Form.Item>
          </Form>
        </div>
        {/* <div className="tcent fmpr">
          <span>Don't have an Account? &nbsp;</span>
          <Link to="/register" style={{ color: "#6BAF43", textDecoration: "underline" }}>
            Register for one now
          </Link>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = ({userLogin}) => {
  return {
    otpVerification: userLogin.otpVerification,
    newOtpInfo: userLogin.newOtpInfo,
    requestNewPassword: userLogin.requestNewPassword,
    user: userLogin.user
  };
}
export default connect(mapStateToProps, { otp_verification, resend_otp, password_req_otp_verification })(withRouter(Otp));
