import React, { Component } from 'react';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import { currencyFormatter, messageNotificationV2 } from '../../../../common/utils';
import "./styles.less"
import { redeem_coupon, client_order_bills } from '../../../../common/actions/order/orderActionDispatch';
import { FAILED, SUCCESS } from '../../../../common/constants';

class Coupon extends Component {
    state ={
        coupon: "",
        disabled: true
}
    handleSubmit = async () =>{
        const {clientOrder, redeem_coupon} = this.props
        const info = {
            coupon: this.state.coupon,
            // order_id: "1ec2a8d3-e1bd-67e2-bc0a-02b8442d9354"
            order_id : clientOrder.id
        }
        await redeem_coupon(info)
        this.formatBonusPrice()
    }
    formatBonusPrice = ()=>{
        const {coupon, client_order_bills, couponStatus, clientOrder} = this.props;
        let priceEffect;
       if(coupon && couponStatus === SUCCESS){
        priceEffect = currencyFormatter(coupon.price)
       } 
       else if(coupon && couponStatus === FAILED){
           let message = coupon.message ? coupon.message : "The selected coupon is invalid"
           messageNotificationV2(message)
           priceEffect = currencyFormatter(clientOrder.price)
       }
       client_order_bills(priceEffect)
    }
    handleChange = (e)=>{
        let coupon = e.target.value
        if(coupon.length > 3) {
            this.setState({coupon, disabled:false})
        }
        else{
            this.setState({disabled: true})
        }
    }
    render() {
        return (
            <div>
                <Input placeholder="Enter Coupon" onChange={this.handleChange} addonAfter={
                <Button className="applyCoupon"  onClick={this.handleSubmit} disabled={this.state.disabled}>Apply</Button>
                }/>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    let { orderDetails } = state;
    return {
      clientOrder: orderDetails.clientOrder,
      coupon: orderDetails.coupon,
      couponStatus: orderDetails.couponStatus,
    }
  }
export default connect(mapStateToProps, {redeem_coupon, client_order_bills})(Coupon);
