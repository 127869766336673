import * as actiontypes from "../actionTypes";

const initialState = {}

const orderInfo = (state = initialState, action) => {
  switch (action.type) {
    case actiontypes.GET_SENDER_DETAILS: {
      return { ...state, senderInfo: action.payload };
    }
    case actiontypes.GET_RECEIVER_DETAILS: {
      return { ...state, receiverInfo: action.payload };
    }
    case actiontypes.CHECK_PRICE: {
      return { ...state, checkPriceDetails: action.payload };
    }
    case actiontypes.DELIVERY_TYPE: {
      return { ...state, deliveryType: action.payload };
    }
    case actiontypes.DELIVERY_TIME: {
      return { ...state, deliveryTime: action.payload };
    }
    case actiontypes.PACKAGE_SIZE: {
      return { ...state, packageSize: action.payload };
    }
    case actiontypes.CREATE_CLIENT_ORDER: {
      return { ...state, clientOrder: action.payload };
    }
    case actiontypes.CLIENT_ORDER_BILLS: {
      return { ...state, clientBills: action.payload };
    }
    case actiontypes.REDEEM_COUPON: {
      return { ...state, coupon: action.payload };
    }
    case actiontypes.COUPON_STATUS: {
      return { ...state, couponStatus: action.payload };
    }
    case actiontypes.MY_ANALYTICS: {
      return { ...state, myAnalytics: action.payload };
    }
    case actiontypes.GET_ORDER_LIST: {
      return { ...state, orderList: action.payload };
    }
    case actiontypes.GET_TRACKING_ID: {
      return { ...state, trackingId: action.payload };
    }
    case actiontypes.TRACK_ORDER: {
      return { ...state, trackingDetails: action.payload };
    }
    case actiontypes.UPDATE_ORDER_WITH_PAYMENT: {
      return { ...state, orderPayment: action.payload };
    }
    default:
      return state;
  }
 };



export default orderInfo